<!-- qa问题搜索有结果 -->
<template>
  <div>
    <headBar/>
    <searchModel/>
    <div class="backGroundBg">
      <div class="con">
        <div class="backHomg" @click="$fun.routerToPage('/questionAndAnswer')">{{ $fanyi('帮助中心') }}</div>
        <div class="searchConBox">
          <leftMenu/>
          <div v-if="loadingStatus===false&&showList.length===0" class="searchBoxRight">
            <h1>
              {{ $fanyi('搜索结果') }} ：{{ $route.query.keyword.replace("+", " ") }}
            </h1>
            <div class="divider"></div>
            <p style="font-size: 20px;line-height: 1.8em;margin-bottom: 14px;">
              ご希望の内容がみつかりませんでした
            </p>
            <div class="fontSize12 color333">
              別のキーワードでも見つからない場合は
            </div>
            <div class="fontSize12 color333">カスタマーサポートまたは担当者までご連絡ください。</div>
          </div>
          <div v-else class="searchBoxRight">
            <h1>関連結果: {{ $route.query.keyword.replace("+", " ") }}</h1>
            <div class="divider"></div>
            <ul class="answerList">
              <li
                  v-for="(searchItem, searchIndex) in showList"
                  :key="searchIndex"
              >
                <h2>
                  <span
                      @click="$fun.routerToPage(`/answerResultPage?menu=${searchItem.category_id}&problemId=${searchItem.id}`)"
                      v-html="searchItem.title"
                  ></span>
                </h2>
                <p><span v-html="searchItem.content"></span>[…]</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headBar from "../../../components/head/HomePageTop.vue";
import leftMenu from "./components/leftMenu.vue";
import footbar from "../../../components/foot/Foot.vue";
import searchModel from "./components/searchModel.vue";

export default {
  data() {
    return {
      page: 1,
      searchArr: [],
      showList: [],
      loadingStatus: null,
    };
  },
  components: {
    headBar,
    footbar,
    searchModel,
    leftMenu,
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    // 获取数据
    getSearchList() {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.loadingStatus = true;
      if (this.$route.query.keyword) {
        this.$api.articleList({keyword: this.$route.query.keyword}).then((res) => {
          this.loading.close();
          this.loadingStatus = false;
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              item.qa_article.forEach((items) => {
                this.showList.push(items)
              })
            })
          }
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";

.backGroundBg {
  background-color: white;
  padding-top: 20px;

  .con {
    width: 1000px;
    margin: 0 auto;

    .backHomg {
      font-size: 14px;
      color: #7a7a7a;
      padding: 10px 10px 24px;
      cursor: pointer;
    }

    .searchConBox {
      display: flex;

      .searchBoxRight {
        flex: 1;
        padding: 10px;

        h1 {
          font-size: 26px;
          font-weight: 500;
          line-height: 1;
          margin-bottom: 20px;
        }

        // 分割线
        .divider {
          height: 1px;
          background-color: #d5d5d5;
          margin-bottom: 20px;
        }

        .answerList {
          h2 {
            margin-bottom: 20px;

            /deep/ span {
              font-weight: bold;
              font-size: 16px;
              cursor: pointer;
              color: #B4272B;
            }

            &:hover {
              text-decoration: underline;
              color: #B4272B;
            }
          }

          p,
          /deep/ p {
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 1.8em;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            * {
              display: inline;
            }

            b {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>
